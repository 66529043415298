import {mapActions, mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";

export default {
  mixins : [permissionMixin, requestLoaderMixin],
  data() {
    return {
      details: this.setDetailInitialValues(),
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      loading : false,
    }
  },
  methods: {
    ...mapActions([
      'getNonConformities',
      'getVesselAuditRecordById',
      'getRootCausesList',
      'getInternalAudit'
    ]),
    // setNcrObservationData() {
    //   const nonConformityType = this.nonConformities[this.$route.params.type].filter(item => item.type === 'ncr');
    //   const observationNoteType = this.nonConformities[this.$route.params.type].filter(item => item.type === 'obs');
    //   this.$store.commit('SET_NONCONFORMITY_TYPE_DATA', nonConformityType);
    //   this.$store.commit('SET_OBSERVATION_TYPE_DATA', observationNoteType);
    //
    //   console.log(this.nonConformityType);
    //   console.log(this.observationNoteType);
    // },
    async getUpdatedNonConformity(showLoader = true) {
      if (showLoader === true) {
        this.showLoaderRequest('FETCHING DATA, PLEASE WAIT...');
        this.loading = true;
      }

      await this.getVesselAuditRecordById(this.$route.params.id);
      await this.getNonConformities({
        id : this.selectedAuditRecord[this.selectedType].id,
        type : this.selectedType
      });
      await this.getInternalAudit({
        id : this.selectedAuditRecord[this.selectedType].id,
        type : this.selectedType
      });
      if (showLoader === true) {
        this.hideLoaderRequest();
        this.loading = false;
        this.scrollToNcrMainDetails('ncr_main_details', 100);
        this.scrollToNcrMainDetails('oe_details', 100);
      }
    },
    setDetailInitialValues() {
      return {
        id : null,
        description : null,
        training_need : 0,
        change_of_procedures : 0,
        recurrence_ashore : '',
        recurrence_on_site : '',
        root_cause_ids : []
      }
    },
    setOneOrZero(value) {
      return value === 0 ? 1 : 0;
    },
    groupByParentCause(rootCauses) {
      const rootCausesList = this.root_causes_list[this.selectedType];
      let groupByParentId = _.groupBy(rootCauses, 'parent_id');
      groupByParentId = _.map(groupByParentId, (item, key) => {
        const parentCause = rootCausesList.filter(parent => {
          return parent.id === parseInt(key);
        })

        return {
          name : parentCause[0].name,
          selected_causes : item
        }
      });

      return groupByParentId;
    },
    scrollToNcrMainDetails(elementId, offset = 0, timeout = 500) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        const yOffset = -offset;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, timeout);
    }
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  computed : {
    ...mapGetters([
      'nonConformities',
      'nonConformityType',
      'observationNoteType',
      'selectedAuditRecord',
      'root_causes_list',
      'internal_audit_reports',
    ]),
    selectedType() {
      return this.$route.params.type;
    }
  }
}
