import {mapActions, mapGetters} from "vuex";



export default {
  data() {
    return {
      oe : this.setOeDetailInitialValues(),
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      objectiveEvidenceId : null,
      attachments: {
        before : {
          images : []
        },
        after : {
          images : []
        }
      },
      imageIdsToDelete : {
        before : [],
        after : []
      },
      selectedImageIndex: 0,
      showSlider: false,
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
      activeNcrIndex : null,
      activeOeIndex : null,
    }
  },
  methods: {
    ...mapActions([
      'getNonConformities',
      'getOECategoriesList',
      'updateOEImageAttachment',
      'updateNonConformities',
      'deleteOeAttachments'
    ]),
    setOeDetailInitialValues() {
      return {
        id : null,
        category_id : null,
        description : null,
        ca_request : '',
        reference : '',
        rectified : 0,
        rectified_date : null,
        rectified_comment : ''
      }
    },
    setOneOrZero(value) {
      return value === 0 ? 1 : 0;
    },
    resetImageIdsHolders() {
      this.imageIdsToDelete.before = [];
      this.imageIdsToDelete.after = [];
    },
    addImage(type) {
      let _this = this;
      let files = this.$refs.before_attachments.files;
      if (type === 'after') {
        files = this.$refs.after_attachments.files;
      }
      for (let counter = 0; counter < files.length; counter++) {
        const file = files[counter];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(e) {
            _this.attachments[type].images.push({
              id : null,
              image_url : e.target.result,
              file : file,
              name : ''
            });
          };
          reader.readAsDataURL(file);
        }
      }

      this.$refs.before_attachments.value = null;
      this.$refs.after_attachments.value = null;
    },
    deleteImage(type, index) {
      const imageToDelete = this.attachments[type].images[index];
      if (imageToDelete.id !== null) {
        this.imageIdsToDelete[type].push(imageToDelete.id);
      }
      this.attachments[type].images.splice(index, 1)
    },
    async initializeAttachments() {
      this.attachments.before.images = [];
      this.attachments.after.images = [];
      const objectiveEvidence = this.ncrMainDetailsData[this.activeNcrIndex].objective_evidences[this.activeOeIndex];
      this.objectiveEvidenceId = objectiveEvidence.id;

      objectiveEvidence.before.forEach(data => {
        this.attachments.before.images.push({
          id : data.id,
          image_url : data.image_url,
          file : null,
          name : ''
        });
      });

      objectiveEvidence.after.forEach(data => {
        this.attachments.after.images.push({
          id : data.id,
          image_url : data.image_url,
          file : null,
          name : ''
        });
      });
    },
    async imageUrlToBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        // Handle the load event to process the image
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = url;
      });
    },

    async convertImagesToBase645(imageData) {
      for (let counter = 0; counter < imageData.length; counter++) {
        let newImageData = imageData[counter];
        if (newImageData.image_url.startsWith('http') === true) {
          newImageData.image_url = await this.imageUrlToBase64(newImageData.image_url);
        }
      }
      return imageData;
    },
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  computed : {
    ...mapGetters([
      'oECategories',
      'nonConformities',
      'nonConformityType',
      'observationNoteType',
    ]),
    newBeforeFiles() {
      return this.attachments.before.images.filter(image => {
        return image.file !== null;
      });
    },
    newAfterFiles() {
      return this.attachments.after.images.filter(image => {
        return image.file !== null;
      })
    },
    ncrMainDetailsData() {
      return this.$route.params.ncr_type === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];
    }
  },
  async created() {
    await this.getOECategoriesList();
  }
}
